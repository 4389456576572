const bodyEl = document.querySelector("body");
const htmlEl = document.querySelector("html");
const modalCloseEl = document.querySelector(".modal__close");
const modalContentEl = document.querySelector(".modal__content");
let lastScroll = window.scrollY;

function setupDynamicLinks() {
  const dynamicLinks = document.querySelectorAll(".dynamic-link");
  dynamicLinks.forEach((linkEl) => {
    linkEl.addEventListener("click", (event) =>
      handleDynamicLinkClick(event, linkEl)
    );
  });
}

function handleDynamicLinkClick(event, linkEl) {
  event.preventDefault();
  const target = linkEl.getAttribute("href");

  const request = new XMLHttpRequest();
  request.open("GET", target);
  request.responseType = "text";

  request.onload = function () {
    openModal(request.response);
  };

  request.send();
}

function restoreScroll() {
  window.scrollTo(window.scrollX, lastScroll)
}

function closeModal() {
  // modalContentEl.innerHtml = null;
  bodyEl.classList.remove("modal-visible");
  // htmlEl.classList.remove("modal-visible");
  restoreScroll();
  setupDynamicLinks();
}

function openModal(content) {
  lastScroll = window.scrollY;
  bodyEl.classList.add("modal-visible");
  // htmlEl.classList.add("modal-visible");
  modalContentEl.innerHTML = content;
  setupDynamicLinks();
}

modalCloseEl.addEventListener("click", closeModal);

setupDynamicLinks();
