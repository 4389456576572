import "normalize.css";

import "./css/style.scss";

import "./css/hero.scss";
import "./css/modal.scss";
import "./css/header.scss";
import "./css/footer.scss";
import "./css/content.scss";
import "./css/artists-list.scss";
import "./css/services-list.scss";
import "./css/activities-list.scss";
import "./css/album-list.scss";
import "./css/label-list.scss";
import "./css/artist-page.scss";

import "./css/a-propos.scss";
import "./css/contact.scss";

import 'regenerator-runtime/runtime'
import "@lottiefiles/lottie-player"
import "./js/scroll.js";
import "./js/dynamic-links.js";
